body {
  margin: 0;
}

ul {
  padding-left: 4px;
}

.pointer {
  cursor: pointer;
}

.ant-btn {
  align-items: center;
  box-shadow: unset;
  display: flex;
  font-weight: 500;
  height: 40px;
  justify-content: center;
}

.ant-btn .ant-btn-icon {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ant-btn:disabled {
  background: #cecece;
  border-color: transparent;
  color: #a2a2a2;
}

.ant-btn.ant-btn-sm {
  height: 32px;
  min-height: 32px;
}

.ant-btn.ant-btn-lg {
  font-size: 14px;
  height: 48px;
  min-height: 48px;
}

.ant-btn-primary.active,
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #6dc1c7;
}

.ant-btn-secondary {
  color: #ffffff;
  background-color: #ff6d38;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #199700;
  border-color: #199700;
}

.ant-modal {
  max-width: calc(100vw - 10px);
}

.ant-modal .ant-modal-title {
  font-size: 22px;
  font-weight: 500;
  line-height: 20px;
  color: #23292c;
}

.ant-modal .ant-modal-close:hover {
  background: #e6f4ef;
  border-radius: 50%;
}

.input-custom {
  height: 30px;
  width: 100%;
  max-width: 200px;
  border-radius: 5px;
  background-color: #ebf1f3;
  border: unset;
  color: #2a2828;
  box-sizing: border-box;
}

.input-custom:hover {
  background-color: #ebf1f3;
}

.input-custom:focus-visible {
  outline-color: grey;
}

.input-custom::placeholder {
  color: grey;
}

.ant-input-number .ant-input-number-input {
  font-weight: 500;
}

.ant-modal .ant-modal-close-x {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-modal .ant-modal-close {
  width: fit-content;
  height: fit-content;
  padding: 2px;
}

.actions-menu-toggler {
  position: relative;
  width: 40px;
  height: 40px;
  background: url("icon/actions_menu.svg") center;
  border-radius: 50%;
  cursor: pointer;
}

.actions-menu-toggler:hover,
.actions-menu-toggler.active {
  background: url("icon/actions_menu_hover.svg") center #1b5568;
}

.actions-menu {
  position: absolute;
  top: 0;
  right: 41px;
  width: 156px;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  list-style: none;
  padding: 0;
  box-shadow: 0 3px 6px #00000029;
  z-index: 999;
}

.actions-menu-item {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 14px;
}

.ant-table-wrapper .ant-table-tbody > tr > td.actions-menu-table-cell {
  padding: 0;
}

.ant-layout-header {
  line-height: initial;
}

.ant-modal .ant-modal-content {
  padding: 22px;
}

.ant-switch.ant-switch-small {
  min-width: 35px;
  height: 19px;
}

.ant-switch.ant-switch-small .ant-switch-handle {
  top: 3px;
  left: 3px;
  width: 13px;
  height: 13px;
}

.ant-switch.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 15px);
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-disabled {
  pointer-events: none;
}

button[disabled] {
  cursor: not-allowed;
}

.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-close {
  top: 5px;
  right: 5px;
}

a {
  color: #0551a7;
}

.ant-select-dropdown .ant-select-item-option-content {
  white-space: pre-line;
}

@media screen and (max-width: 768px) {
  .ant-modal .ant-modal-content {
    max-height: 90vh;
    overflow: auto;
    padding: 22px 18px;
  }

  .ant-btn.ant-btn-round {
    font-size: 16px;
  }
}
